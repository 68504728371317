import classNames from 'classnames';
import { func, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ExternalLink, Logo, NamedLink } from '../../components';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './Footer.module.css';

const mainLandingPagePath = '/';
const coachLandingPagePath = '/become-a-coach';
const EVENTS_KNOWLEDGE_HUB_SLUG = 'boldly-events';
const JOIN_TEAM_KNOWLEDGE_HUB_SLUG = 'join-the-boldly-team';

const Footer = props => {
  const {
    rootClassName,
    className,
    intl,
    handleMoveToHowBoldlyWorksSection,
    handleMoveToCoachingCultureSection,
  } = props;
  const history = useHistory();
  const classes = classNames(rootClassName || css.root, className);
  const isCoachHomePage = history.location.pathname === coachLandingPagePath;
  const { siteToSignup } = config;

  const handleClickHowBoldlyWorks = e => {
    e.preventDefault();

    history.location.pathname === mainLandingPagePath ||
    history.location.pathname === coachLandingPagePath
      ? handleMoveToHowBoldlyWorksSection()
      : (window.location.href = '/');
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <ExternalLink href="/" target="_self" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </ExternalLink>
              <div className={css.organizationInfoParagraph}>
                <FormattedMessage id="Footer.organizationFirstParagraph" />
              </div>
              <div className={css.organizationInfoParagraph}>
                <strong>
                  <FormattedMessage id="Footer.organizationSecondParagraphTitle" />
                </strong>
                <FormattedMessage id="Footer.organizationSecondParagraph" />
              </div>
              <div className={css.organizationInfoParagraph}>
                <strong>
                  <FormattedMessage id="Footer.organizationThirdParagraphTitle" />
                </strong>
                <FormattedMessage id="Footer.organizationThirdParagraph" />
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.company" />
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/" className={css.link} onClick={handleClickHowBoldlyWorks}>
                    <FormattedMessage id="Footer.toLandingPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="CaseStudyNavigationPage">
                    <FormattedMessage id="Footer.toCaseStudies" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  {isCoachHomePage ? (
                    <ExternalLink className={css.link} key="Footer" href={siteToSignup}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </ExternalLink>
                  ) : (
                    <ExternalLink href="/become-a-coach" target="_self" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </ExternalLink>
                  )}
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    key="linkToAboutUs"
                    name="KnowledgeHubPage"
                    params={{ slug: 'about-us' }}
                  >
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    name="KnowledgeHubPage"
                    params={{ slug: JOIN_TEAM_KNOWLEDGE_HUB_SLUG }}
                  >
                    <FormattedMessage id="Footer.careers" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.resources" />
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/coaching-formats" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.coaching" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="BlogPage" className={css.link}>
                    <FormattedMessage id="Footer.toBlog" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="KnowledgeHubNavigationPage">
                    <FormattedMessage id="Footer.toKnowledgeHubNavigation" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    name="KnowledgeHubPage"
                    params={{ slug: EVENTS_KNOWLEDGE_HUB_SLUG }}
                  >
                    <FormattedMessage id="Footer.events" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href="https://zc.vg/VQW8F" target="_self">
                    <FormattedMessage id="Footer.forIndividuals" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.locations" />
                </li>
                <li className={css.listItemSubHeading}>
                  <FormattedMessage id="Footer.americas" />
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/peru" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.peru" />
                  </ExternalLink>
                </li>
                <li className={css.listItemSubHeading}>
                  <FormattedMessage id="Footer.apac" />
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/australia" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.australia" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/hongKong" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.hongKong" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/new-zealand" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.newZealand" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/singapore" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.singapore" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/uae" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.uae" />
                  </ExternalLink>
                </li>
                <li className={css.listItemSubHeading}>
                  <FormattedMessage id="Footer.emea" />
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="/belgium" target="_self" className={css.link}>
                    <FormattedMessage id="Footer.belgium" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={css.seperateLineContainer}>
            <div className={css.line}></div>
            <div className={css.dot}></div>
            <div className={css.line}></div>
          </div>
          <div className={css.footerBottom}>
            <div className={css.leftFooterBottom}>
              <a
                href="https://www.linkedin.com/company/boldly-app/posts/?feedView=all"
                target="_blank"
              >
                <img
                  src="https://cdn.prod.website-files.com/66cdc4383d50e9e564339725/66d0e08ebc9ec213a144cee7_linkedin-box-fill.svg"
                  loading="lazy"
                  alt="linkedinLogo"
                />
              </a>
            </div>
            <div className={css.middleFooterBottom}>
              <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
            </div>
            <div className={css.rightFooterBottom}>
              <FormattedMessage
                id="Footer.copyright"
                values={{
                  companyName: chunks => <span className={css.companyName}>{chunks}</span>,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  handleMoveToHowBoldlyWorksSection: () => {},
  handleMoveToCoachingCultureSection: () => {},
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  handleMoveToHowBoldlyWorksSection: func,
  handleMoveToCoachingCultureSection: func,
};

export default injectIntl(Footer);
