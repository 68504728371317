/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const OTHER_OPTION = 'other';

export const filters = [
  {
    id: 'languages',
    label: 'Languages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'english', label: 'English' },
        { key: 'arabic', label: 'Arabic' },
        { key: 'bengali', label: 'Bengali' },
        { key: 'cantonese', label: 'Cantonese' },
        { key: 'filipino', label: 'Filipino' },
        { key: 'french', label: 'French' },
        { key: 'german', label: 'German' },
        { key: 'hindi', label: 'Hindi' },
        { key: 'italian', label: 'Italian' },
        { key: 'japanese', label: 'Japanese' },
        { key: 'javanese', label: 'Javanese' },
        { key: 'korean', label: 'Korean' },
        { key: 'malay', label: 'Malay' },
        { key: 'mandarin', label: 'Mandarin' },
        { key: 'portuguese', label: 'Portuguese' },
        { key: 'punjabi', label: 'Punjabi' },
        { key: 'russian', label: 'Russian' },
        { key: 'spanish', label: 'Spanish' },
        { key: 'thai', label: 'Thai' },
        { key: 'vietnamese', label: 'Vietnamese' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
      isUseCustomFilterPopup: true,
    },
  },
  {
    id: 'price',
    label: 'Rate',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_minPriceAmount'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 100,
      max: 2000,
      step: 5,
    },
  },
  {
    id: 'industries',
    label: 'Industries',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_industries'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accommodationandFoodServices', label: 'Accommodation and Food Services' },
        { key: 'administration', label: 'Administration - Hospital, School, Prison etc' },
        { key: 'agileAgriculture', label: 'Agriculture, Forestry and Fishing' },
        { key: 'businessFranchises', label: 'Business Franchises' },
        { key: 'constructionProperty', label: 'Construction & Property' },
        { key: 'consumer', label: 'Consumer' },
        { key: 'education', label: 'Education' },
        { key: 'electricity', label: 'Electricity, Gas, Water and Waste Services' },
        { key: 'financialServices', label: 'Financial Services' },
        { key: 'government', label: 'Government' },
        { key: 'industrial', label: 'Industrial' },
        { key: 'informationMedia', label: 'Information Media and Telecommunications' },
        { key: 'lifeSciences', label: 'Life Sciences and Pharma' },
        { key: 'mining', label: 'Mining' },
        { key: 'notForProfit', label: 'Not-for-Profit' },
        { key: 'oilGas', label: 'Oil & Gas' },
        { key: 'personalServices', label: 'Personal Services' },
        { key: 'professionalServices', label: 'Professional Services' },
        { key: 'recreationServices', label: 'Recreation Services' },
        { key: 'retailTrade', label: 'Retail Trade - Online/Offline' },
        { key: 'startUp', label: 'Start-up' },
        { key: 'sporting', label: 'Sporting' },
        { key: 'technology', label: 'Technology' },
        { key: 'theArts', label: 'The Arts' },
        { key: 'transport', label: 'Transport, Shipping & Logistics' },
        { key: 'wholesaleTrade', label: 'Wholesale Trade' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
      isUseCustomFilterPopup: true,
    },
  },
  {
    id: 'location',
    label: 'Location',
    type: 'LocationFilter',
    group: 'primary',
    queryParamNames: ['address', 'bounds', 'origin'],
    config: {},
  },
  {
    id: 'coachingFormat',
    label: 'Format',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_coachingFormat'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'online', label: 'Online' },
        { key: 'faceToFace', label: 'Face to face' },
      ],
    },
  },
  {
    id: 'coachingStyles',
    label: 'Coaching Styles',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_coachingStyles'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'agileCoaching', label: 'Agile Coaching' },
        { key: 'behaviouralGrow', label: 'Behavioural / GROW' },
        { key: 'businessCoaching', label: 'Business Coaching' },
        { key: 'cognitiveBehavioural', label: 'Cognitive Behavioural' },
        { key: 'existential', label: 'Existential' },
        { key: 'gestalt', label: 'Gestalt' },
        { key: 'groupCoaching', label: 'Group Coaching' },
        { key: 'groupRelations', label: 'Group Relations' },
        { key: 'mentoring', label: 'Mentoring' },
        { key: 'motivationalInterviewing', label: 'Motivational Interviewing' },
        { key: 'ontological', label: 'Ontological' },
        { key: 'psychodynamic', label: 'Psychodynamic' },
        { key: 'solutionFocused', label: 'Solution Focused' },
        { key: 'strengthsBased', label: 'Strengths-based' },
        { key: 'teamCoaching', label: 'Team Coaching' },
        { key: 'transactionalAnalysis', label: 'Transactional Analysis' },
        { key: 'transpersonal', label: 'Transpersonal' },
        { key: 'transformational', label: 'Transformational' },
        { key: 'tutor', label: 'Tutor' },
        { key: 'workshopProgrammeSupport', label: 'Workshop/ Programme Support' },
        { key: 'lifeCoaching', label: 'Life Coaching' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
    },
  },
  {
    id: 'developmentSituations',
    label: 'Development Situations',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_developmentSituations'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'boardDevelopment', label: 'Board Development' },
        { key: 'careerTransitions', label: 'Career Transitions' },
        { key: 'crossCulturalCoaching', label: 'Cross-cultural Coaching' },
        { key: 'cultureTransitions', label: 'Culture Transitions' },
        { key: 'definingPurposeMotivation', label: 'Defining Purpose & Motivation' },
        { key: 'downsizing', label: 'Downsizing' },
        { key: 'earlyCareers', label: 'Early Careers' },
        { key: 'entrepreneurialSupport', label: 'Entrepreneurial Support' },
        { key: 'habitChange', label: 'Habit Change' },
        { key: 'leadershipDevelopment', label: 'Leadership Development' },
        { key: 'managementDevelopment', label: 'Management Development' },
        { key: 'organisationalNavigation', label: 'Organisational Navigation' },
        { key: 'parentalTransitions', label: 'Parental Transitions' },
        { key: 'productivityEnablement', label: 'Productivity Enablement' },
        { key: 'relationshipDevelopment', label: 'Relationship Development' },
        { key: 'remoteTeamManagement', label: 'Remote Team Management' },
        { key: 'retirementCoaching', label: 'Retirement Coaching' },
        { key: 'skillDevelopment', label: 'Skill Development' },
        { key: 'stressManagement', label: 'Stress Management' },
        { key: 'workLifeBalance', label: 'Work/Life Balance' },
        { key: 'impactConfidence', label: 'Impact Confidence' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
    },
  },
  {
    id: 'coachingAccreditations',
    label: 'Coaching Accreditations',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_coachingAccreditations'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'icfACC', label: 'ICF - ACC' },
        { key: 'icfPCC', label: 'ICF - PCC' },
        { key: 'icfMCC', label: 'ICF - MCC' },
        { key: 'emccEIA', label: 'EMCC - EIA' },
        { key: 'emccEQA', label: 'EMCC - EQA' },
        { key: 'emccESIA', label: 'EMCC - ESIA' },
        { key: 'emccESQA', label: 'EMCC - ESQA' },
        { key: 'acCoach', label: 'AC - Coach' },
        { key: 'acCoachingSupervisor', label: 'AC - Coaching Supervisor' },
        { key: 'lapsed', label: 'Lapsed' },
        { key: 'qualificationsPending', label: 'Qualifications Pending' },
        { key: 'wabcRCC', label: 'WABC - RCC' },
        { key: 'wabcCBC', label: 'WABC - CBC' },
        { key: 'wabcCMBC', label: 'WABC - CMBC' },
        { key: 'wabcChBC', label: 'WABC - ChBC' },
        { key: 'iacMasteries', label: 'IAC - Masteries Practitioner' },
        { key: 'iacCMC', label: 'IAC - CMC' },
        { key: 'iacMMC', label: 'IAC - MMC' },
        { key: 'apecsAccreditedSupervisor', label: 'APECS Accredited Supervisor' },
        { key: 'apecsAccreditedExecutiveCoach', label: 'APECS Accredited Executive Coach' },
        {
          key: 'apecsAccreditedExecutiveTeamCoach',
          label: 'APECS Accredited Executive Team Coach',
        },
        { key: 'apecsFellow', label: 'APECS Fellow' },
        { key: 'apecsPractisingSupervisor', label: 'APECS Practising Supervisor' },
        { key: 'apecsPractisingExecutiveCoach', label: 'APECS Practising Executive Coach' },
        {
          key: 'apecsPractisingExecutiveTeamCoach',
          label: 'APECS Practising Executive Team Coach',
        },
        { key: 'degreeMAPsychology', label: 'Degree - MA in Psychology (other)' },
        { key: 'degreePhDPsychology', label: 'Degree - Ph.D in Psychology (other)' },
        { key: 'degreeBAPsychology', label: 'Degree - BA in Psychology' },
        { key: 'degreeMACoachingPsychology', label: 'Degree - MA in Coaching Psychology' },
        {
          key: 'degreePhDCoachingPsychology',
          label: 'Degree - Ph.D in Coaching Psychology',
        },
        { key: 'degreeExecMACoaching', label: 'Degree - Exec MA in Coaching' },
        { key: 'degreeGradCert', label: 'Degree - Grad Cert / Diploma in Coaching' },
        { key: 'ecaBasicLevel', label: 'ECA - Basic Level' },
        { key: 'ecaAdvancedLevel', label: 'ECA - Advanced Level' },
        { key: 'ecaExpertLevel', label: 'ECA - Expert Level' },
        { key: 'eascMember', label: 'EASC - Member' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
      isHideFromFilter: true
    },
  },
  {
    id: 'assessmentAccreditations',
    label: 'Assessment Accreditations',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_assessmentAccreditations'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'theCaliperProfile', label: 'The Caliper Profile' },
        { key: 'hogan', label: 'Hogan' },
        { key: 'SHL', label: 'SHL' },
        { key: 'MBTI', label: 'MBTI' },
        { key: 'psychometricOther', label: 'Psychometric Other' },
        { key: 'diSC', label: 'DiSC' },
        { key: 'theEQI2', label: 'The EQ-i 2.0' },
        { key: 'theWorkplaceBigFive', label: 'The Workplace Big Five' },
        { key: 'cliftonStrengthsFinder', label: 'Clifton StrengthsFinder' },
        { key: 'viaStrengths', label: 'ViaStrengths' },
        { key: 'various', label: '360 Various' },
        { key: 'birkman', label: 'Birkman' },
        { key: 'personalityFactors', label: '16 Personality Factors' },
        {
          key: 'strengthDeploymentInventory',
          label: 'Strength Deployment Inventory',
        },
        { key: 'enneagram', label: 'Enneagram' },
        { key: 'insights', label: 'Insights' },
        {
          key: 'thomasKilmannConflictModeInstrument',
          label: 'Thomas-Kilmann Conflict Mode Instrument',
        },
        { key: 'belbinTeamRoles', label: 'Belbin Team Roles' },
        { key: 'strongInterestInventory', label: 'Strong Interest Inventory' },
        { key: 'FIROB', label: 'FIRO-B' },
        {
          key: 'californiaPsychologicalInventory',
          label: 'California Psychological Inventory',
        },
        { key: 'wellscan', label: 'Wellscan' },
        { key: 'harrisonAssessments', label: 'Harrison Assessments' },
        { key: OTHER_OPTION, label: 'Other' },
      ],
      isHideFromFilter: true
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const BUYER_ROLE = 'buyer';
export const COACH_ROLE = 'coach';
export const COACHEE_ROLE = 'coachee';
export const ADMIN_ROLE = 'admin';
export const GUEST_ROLE = 'guest'; //non login user
export const userFilters = [
  {
    id: 'role',
    label: 'Role',
    config: {
      options: [{ key: BUYER_ROLE, label: 'Looking for a coach' }, { key: COACH_ROLE, label: 'A coach' }],
    },
  },
];

export const paymentMethodsFilters = [
  {
    id: 'paymentMethods',
    label: 'Payment methods',
    config: {
      options: [
        { key: 'invoice', label: 'Invoice' },
        { key: 'creditCard', label: 'Credit Card' }
      ]
    }
  }
];

export const careerPhaseOptions = [
  { key: 'earlyCareer', label: 'Early career' },
  { key: 'management', label: 'Mid career - management' },
  { key: 'nonManagement', label: 'Mid career - subject matter expert (non management)' },
  { key: 'leadership', label: 'Leadership' },
  { key: 'executive', label: 'Executive' },
];

export const coachingFormatOptionsForDropdown = [
  { key: 'online', label: 'Online' },
  { key: 'faceToFace', label: 'Face to face' },
  { key: 'both', label: 'Either Online or Face to Face' }
]

export const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
];

export const MOST_RECENT_BLOGS = 'most_recent';
export const COACHING_BLOGS = 'coaching';
export const COACHING_CULTURE_BLOGS = 'coaching_culture';
export const SHORT_LIST_TAB = 'short-list';
export const CHEMISTRY_TAB = 'chemistry';
export const CHEMISTRY_SESSION_LIMIT = 3;
export const MOST_RECENT_CASE_STUDY = 'most_recent';
export const COACHING_CASE_STUDY = 'coaching';
export const COACHING_CULTURE_CASE_STUDY = 'coaching_culture';

export const accountStatus = {
  waiting: 'waiting',
  claim: 'claim',
};

export const optInValueMaybe = {
  checked: true,
  unchecked: false,
};

export const INTERESTED_ENGAGEMENT = 'interested';
export const NOT_INTERESTED_ENGAGEMENT = 'notInterested';

// Knowledge Hub's article type
export const FOR_CLIENT_ARTICLE = 'for_client';
export const FOR_COACH_ARTICLE = 'for_coach';
export const FOR_COACHEE_ARTICLE = 'for_coachee';

export const knowledgeHubArticles = [
  { key: FOR_CLIENT_ARTICLE, label: 'For Client' },
  { key: FOR_COACH_ARTICLE, label: 'For Coach' },
  { key: FOR_COACHEE_ARTICLE, label: 'For Coachee' },
];

export const REQUIRED_LABEL = '*';