import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { ENGAGEMENT_LISTING, GROUP_AND_TEAM_COACHING, ONE_VS_ONE_COACHING, ON_DEMAND_COACHING } from './util/types';
import { ADMIN_ROLE, BUYER_ROLE, COACHEE_ROLE, COACH_ROLE, GUEST_ROLE } from './marketplace-custom-config';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const BlogPage = loadable(() => import(/* webpackChunkName: "BlogPage" */ './containers/BlogPage/BlogPage'));
const SingleBlogPage = loadable(() => import(/* webpackChunkName: "SingleBlogPage" */ './containers/SingleBlogPage/SingleBlogPage'));
const CoachListPage = loadable(() => import(/* webpackChunkName: "CoachListPage" */ './containers/CoachListPage/CoachListPage'));
const CaseStudyNavigationPage = loadable(() => import(/* webpackChunkName: "CaseStudyNavigationPage" */ './containers/CaseStudyNavigationPage/CaseStudyNavigationPage'));
const CaseStudyPage = loadable(() => import(/* webpackChunkName: "CaseStudyPage" */ './containers/CaseStudyPage/CaseStudyPage'));
const KnowledgeHubNavigationPage = loadable(() => import(/* webpackChunkName: "KnowledgeHubNavigationPage" */ './containers/KnowledgeHubNavigationPage/KnowledgeHubNavigationPage'));
const KnowledgeHubPage = loadable(() => import(/* webpackChunkName: "KnowledgeHubPage" */ './containers/KnowledgeHubPage/KnowledgeHubPage'));
const InboxBasePage = loadable(() => import(/* webpackChunkName: "InboxBasePage" */'./containers/InboxBasePage/InboxBasePage'));
// IMPORTANT NOTE: Whenever adding a new static page, please update it to the defaultPublicPaths 
// variable in the file server/resources/sitemap/sitemapDefault.

// Styleguide helps you to review current components and develop new ones

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => window.location.href = '/';

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

const routeConfiguration = () => ([
  {
    path: '/a',
    name: 'GetaCoachPage',
    component: ()=><></>,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/s',
    name: 'SearchPage',
    forRole: [BUYER_ROLE, GUEST_ROLE],
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/l',
    name: 'ListingBasePage',
    auth: true,
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/:slug/:id',
    name: 'ListingPage',
    auth: true,
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/:slug/:id/checkout',
    name: 'CheckoutPage',
    auth: true,
    component: CheckoutPage,
    setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
  },
  {
    path: '/l/:slug/:id/:variant',
    name: 'ListingPageVariant',
    auth: true,
    authPage: 'LoginPage',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/new',
    name: 'NewListingPage',
    auth: true,
    component: () => (
      <NamedRedirect
        name="EditListingPage"
        params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
      />
    ),
  },
  {
    path: '/l/:slug/:id/:type/:tab',
    name: 'EditListingPage',
    auth: true,
    component: EditListingPage,
    extraProps: { allowOnlyOneListing: true },
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },
  {
    path: '/l/:slug/:id/:type/:tab/:returnURLType',
    name: 'EditListingStripeOnboardingPage',
    auth: true,
    component: EditListingPage,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },
  {
    path: '/engagement/new',
    name: 'NewEngagementPage',
    auth: true,
    component: () => (
      <NamedRedirect
        name="EditEngagementPage"
        params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'process' }}
      />
    ),
  },
  {
    path: '/engagement/:slug/:id/:type/:tab',
    name: 'EditEngagementPage',
    auth: true,
    component: props => <EditListingPage {...props} listingType={ENGAGEMENT_LISTING} />,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },

  // Canonical path should be after the `/l/new` path since they
  // conflict and `new` is not a valid listing UUID.
  {
    path: '/l/:id',
    name: 'ListingPageCanonical',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/u',
    name: 'ProfileBasePage',
    component: RedirectToLandingPage,
  },
  {
    path: '/u/:id',
    name: 'ProfilePage',
    component: ProfilePage,
    loadData: pageDataLoadingAPI.ProfilePage.loadData,
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ProfileSettingsPage,
  },

  // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
  // so that in the error case users can be redirected back to the LoginPage
  // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
  {
    path: '/login',
    name: 'LoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login' },
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: AuthenticationPage,
    extraProps: { tab: 'signup' },
  },
  {
    path: '/confirm',
    name: 'ConfirmPage',
    component: AuthenticationPage,
    extraProps: { tab: 'confirm' },
  },
  {
    path: '/recover-password',
    name: 'PasswordRecoveryPage',
    component: PasswordRecoveryPage,
  },
  {
    path: '/inbox',
    name: 'InboxBasePage',
    auth: true,
    authPage: 'LoginPage',
    component: InboxBasePage,
  },
  {
    path: '/inbox/:tab',
    name: 'InboxPage',
    auth: true,
    authPage: 'LoginPage',
    component: InboxPage,
    loadData: pageDataLoadingAPI.InboxPage.loadData,
  },
  {
    path: '/order/:id',
    name: 'OrderPage',
    auth: true,
    authPage: 'LoginPage',
    component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
  },
  {
    path: '/order/:id/details',
    name: 'OrderDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: TransactionPage,
    extraProps: { transactionRole: 'customer' },
    loadData: params =>
      pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
    setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
  },
  {
    path: '/sale/:id',
    name: 'SalePage',
    auth: true,
    authPage: 'LoginPage',
    component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
  },
  {
    path: '/sale/:id/details',
    name: 'SaleDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: TransactionPage,
    extraProps: { transactionRole: 'provider' },
    loadData: params =>
      pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
  },
  {
    path: '/engagements',
    name: 'ManageEngagementsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ManageListingsPage,
    loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
  },
  {
    path: '/account',
    name: 'AccountSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: () => <NamedRedirect name="ContactDetailsPage" />,
  },
  {
    path: '/account/contact-details',
    name: 'ContactDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ContactDetailsPage,
    loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
  },
  {
    path: '/account/change-password',
    name: 'PasswordChangePage',
    auth: true,
    authPage: 'LoginPage',
    component: PasswordChangePage,
  },
  {
    path: '/account/payments',
    name: 'StripePayoutPage',
    auth: true,
    authPage: 'LoginPage',
    forRole: [ADMIN_ROLE],
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payments/:returnURLType',
    name: 'StripePayoutOnboardingPage',
    auth: true,
    authPage: 'LoginPage',
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payment-methods',
    name: 'PaymentMethodsPage',
    auth: true,
    authPage: 'LoginPage',
    component: PaymentMethodsPage,
    loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServicePage',
    component: TermsOfServicePage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  {
    path: '/notfound',
    name: 'NotFoundPage',
    component: props => <NotFoundPage {...props} />,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /reset-password endpoint
  {
    path: '/reset-password',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /verify-email endpoint
  {
    path: '/verify-email',
    name: 'EmailVerificationPage',
    auth: true,
    authPage: 'LoginPage',
    component: EmailVerificationPage,
    loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: BlogPage,
    loadData: pageDataLoadingAPI.BlogPage.loadData,
  },
  {
    path: '/blog/:slug',
    name: 'SingleBlogPage',
    component: SingleBlogPage,
    loadData: pageDataLoadingAPI.SingleBlogPage.loadData,
  },
  {
    path: '/coach-list/:engagementId',
    name: 'CoachListPage',
    component: CoachListPage,
    auth: true,
    authPage: 'LoginPage',
    loadData: pageDataLoadingAPI.CoachListPage.loadData,
  },
  {
    path: '/casestudies',
    name: 'CaseStudyNavigationPage',
    component: CaseStudyNavigationPage,
    loadData: pageDataLoadingAPI.CaseStudyNavigationPage.loadData,
  },
  {
    path: '/casestudies/:slug',
    name: 'CaseStudyPage',
    component: CaseStudyPage,
    loadData: pageDataLoadingAPI.CaseStudyPage.loadData,
  },
  {
    path: '/knowledge-hub',
    name: 'KnowledgeHubNavigationPage',
    component: KnowledgeHubNavigationPage,
    loadData: pageDataLoadingAPI.KnowledgeHubNavigationPage.loadData,
  },
  {
    path: '/knowledge-hub/:slug',
    name: 'KnowledgeHubPage',
    component: KnowledgeHubPage,
    loadData: pageDataLoadingAPI.KnowledgeHubPage.loadData,
  },
]);

export default routeConfiguration;
