import React from 'react';
import { func, string, oneOf, object, array, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, ExternalLink, NamedLink, Process } from '../../components';
import config from '../../config';
import { COACH_LISTING, ENGAGEMENT_LISTING, LISTING_STATE_DRAFT } from '../../util/types';
import ProcessDiagram from './ProcessDiagram/ProcessDiagram';
import porcessImage from '../../assets/Coach_Sign_Up_Process.png'

import css from './EditListingProcessPanel.module.css';

const { howItWorksSlug } = config;

const EditListingProcessPanel = props => {
  const {
    className,
    rootClassName,
    onSubmit,
    submitButtonText,
    processContentList,
    listingType,
    currentListing,
    updateUserInProgress,
  } = props;

  const { state } = currentListing?.attributes || {};
  const classes = classNames(rootClassName || css.root, className);
  const isDraftListing = !state || state === LISTING_STATE_DRAFT;

  const panelTitle = <FormattedMessage id="EditListingProcessPanel.createListingTitle" />;

  return (
    <div className={classes}>
      <h1 className={css.panelTitle}>{panelTitle}</h1>
      {listingType === COACH_LISTING ? (
        <>
          <h3 className={css.howItWorks}>
            <FormattedMessage id="EditListingProcessPanel.howTheProcessWorks" />
          </h3>

          <img
            className={css.processImage}
            src={porcessImage}
          />
        </>
      ) : (
        <>
          <h3 className={css.howItWorks}>
            <NamedLink name="KnowledgeHubPage" params={{ slug: howItWorksSlug }}>
              <FormattedMessage id="EditListingProcessPanel.howItWorks" />
            </NamedLink>
          </h3>
          <p>
            <FormattedMessage
              id="EditListingProcessPanel.engagementInformation"
              values={{
                breakLine: <br />,
                feesLink: (
                  <NamedLink
                    name="KnowledgeHubPage"
                    params={{
                      slug: howItWorksSlug,
                    }}
                  >
                    <FormattedMessage id="EditListingProcessPanel.feesLink" />
                  </NamedLink>
                ),
              }}
            />
          </p>
          <ProcessDiagram />
          <p>
            <FormattedMessage
              id="EditListingProcessPanel.engagementDescription"
              values={{
                emailLink: (
                  <ExternalLink href={'mailto:connect@boldly.app'}>
                    <FormattedMessage id="EditListingProcessPanel.emailLink" />
                  </ExternalLink>
                ),
              }}
            />
          </p>
        </>
      )}
      {isDraftListing && (
        <Button className={css.submitButton} onClick={onSubmit} inProgress={updateUserInProgress}>
          {submitButtonText}
        </Button>
      )}
    </div>
  );
};

EditListingProcessPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listingType: COACH_LISTING,
  currentListing: null,
  processContentList: [],
  updateUserInProgress: false,
};

EditListingProcessPanel.propTypes = {
  className: string,
  rootClassName: string,
  listingType: oneOf([COACH_LISTING, ENGAGEMENT_LISTING]),

  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  currentListing: object,
  processContentList: array,
  updateUserInProgress: bool,
};

export default EditListingProcessPanel;
