import classNames from 'classnames';
import { bool, func, object, string, array } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChemistrySessionInvitationForm } from '../../forms';
import { Avatar } from '..';
import { createSlug } from '../../util/urlHelpers';
import IconChemistry from '../IconChemistry/IconChemistry';
import IconCircleClose from '../IconCircleClose/IconCircleClose';
import Modal from '../Modal/Modal';
import NamedLink from '../NamedLink/NamedLink';
import Skeleton from '../Skeleton/Skeleton';
import SplitButton from '../SplitButton/SplitButton';
import FormattedMessageWithClass from '../FormattedMessageWithClass/FormattedMessageWithClass';
import { CHEMISTRY_SESSION_LIMIT } from '../../marketplace-custom-config';
import Tooltip from '../Tooltip/Tooltip';

import css from './CoachCard.module.css';

const MINIMUM_EMAIL_NUMBER = 2;

const CoachCard = props => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const {
    listing: {
      id: { uuid: coachListingId },
      attributes: {
        title,
        description,
        publicData: {
          location: { address },
          languagesToSearch,
        },
      },
      author: {
        id: { uuid: coachId },
        attributes: {
          profile: { displayName },
        },
      },
    },
    onRemove,
    isOpacity,
    disableCloseButton,
    onManageDisableScrolling,
    onGetCoachEmailToInvite,
    fetchCoachEmailRequest,
    coacheeEmail,
    buyerEmail,
    coachEmail,
    buyerInfo: {
      id: { uuid: buyerId },
    },
    chemistryList,
    onBookChemistry,
    scheduleChemistryRequest,
    isBuyer,
    isClosedListing,
    removeCoachTooltipMessage,
    closeBtnTooltipDirection,
    isCoachRespond,
    waitingCoachResponseMessage,
    bookChemistryBtnTooltipDirection,
  } = props;

  const parseChemistryList = chemistryList => {
    let emails = [];
    for (const key in chemistryList) {
      if (key === 'coachListingId') continue;
      if (key === 'participants') {
        emails = [...emails, ...chemistryList[key]];
      } else {
        emails = [...emails, chemistryList[key].email];
      }
    }
    return emails;
  };

  const currentChemistryInfo = chemistryList.find(
    element => element.coach.listingId === coachListingId
  );
  const isDisabled = !!(
    chemistryList.length === CHEMISTRY_SESSION_LIMIT ||
    currentChemistryInfo ||
    isClosedListing ||
    !isCoachRespond
  );

  const shouldShowInvitationForm = !fetchCoachEmailRequest && coachEmail && coacheeEmail;

  const displayFirstName = displayName.split(' ')[0];

  const initialValues = currentChemistryInfo
    ? { emails: parseChemistryList(currentChemistryInfo) }
    : {
        emails: [coacheeEmail, coachEmail],
      };

  const options = [
    {
      key: coachEmail,
      label: intl.formatMessage({ id: 'CoachCard.coachEmail' }, { coachEmail }),
      disabled: true,
    },
    {
      key: coacheeEmail,
      label: intl.formatMessage(
        { id: 'CoachCard.coacheeEmail' },
        {
          coacheeEmail,
        }
      ),
      disabled: true,
    },
    {
      key: buyerEmail,
      label: intl.formatMessage({ id: 'CoachCard.buyerEmail' }, { buyerEmail }),
    },
  ];

  if (currentChemistryInfo) {
    currentChemistryInfo.participants.forEach(mail => {
      options.push({
        key: mail,
        label: mail,
      });
    });
  }

  const handleSubmit = ({ emails }) => {
    const getMailList = () => {
      const [coachMail, coacheeMail, maybeBuyerMail, ...rest] = emails;
      const coach = {
        id: coachId,
        listingId: coachListingId,
      };
      const buyer = {
        id: buyerId,
      };

      if (emails.length === MINIMUM_EMAIL_NUMBER) {
        return { coach, participants: [] };
      }

      const isSelectedBuyerEmail = maybeBuyerMail === buyerEmail;
      return isSelectedBuyerEmail
        ? { coach, buyer, participants: [...rest] }
        : { coach, participants: [maybeBuyerMail, ...rest] };
    };

    onBookChemistry(getMailList(), coachId, coachListingId).then(() => {
      setIsOpen(false);
    });
  };

  const handleClickCard = e => {
    if (isOpacity) {
      e.preventDefault();
    }
  };

  const handleClickChemistryButton = e => {
    e.preventDefault();
    setIsOpen(true);
  };

  useEffect(() => {
    isOpen && onGetCoachEmailToInvite(coachListingId);
  }, [coachListingId, isOpen]);

  const linkProps = {
    name: 'ListingPage',
    params: { id: coachListingId, slug: createSlug(title) },
  };

  const bookChemistryButton = (
    <SplitButton
      disabled={isDisabled}
      onClick={handleClickChemistryButton}
      className={classNames(css.chemistryButton, { [css.disable]: isDisabled })}
      iconClassName={classNames({ [css.iconClassName]: isDisabled })}
      icon={<IconChemistry isCoachListing={true} />}
      buttonText={<FormattedMessage id="BookingPanel.bookChemistrySession" />}
      iconRootClassName={css.icon}
      onMouseEnterCustomActive
      onMouseLeaveCustomActive
    />
  );

  return (
    <>
      <div className={css.coachCard} onClick={handleClickCard}>
        {isOpacity ? (
          <Skeleton className={css.skeleton} />
        ) : (
          <>
            <Avatar
              avatarLinkProps={linkProps}
              user={props.listing.author}
              className={css.rootForImage}
              initialsClassName={css.avatarText}
            />
            <div className={css.info}>
              <div className={css.heading}>
                <NamedLink {...linkProps} className={css.title}>
                  <h3>{displayFirstName}</h3>
                </NamedLink>
                {currentChemistryInfo && (
                  <FormattedMessageWithClass
                    id="CoachCard.inviteSent"
                    className={css.invitedLabelOnMobile}
                  />
                )}
              </div>
              <div>{address}</div>
              <div>
                <FormattedMessage id="CoachCard.languages" values={{ lang: languagesToSearch }} />
              </div>
              <div>{description}</div>
            </div>
            {currentChemistryInfo && (
              <FormattedMessageWithClass id="CoachCard.inviteSent" className={css.invitedLabel} />
            )}
            <div className={css.buttonLayout}>
              {!isCoachRespond ? (
                <Tooltip
                  content={waitingCoachResponseMessage}
                  contentClassName={css.bookChemistryTooltipContent}
                  {...bookChemistryBtnTooltipDirection}
                >
                  {bookChemistryButton}
                </Tooltip>
              ) : (
                bookChemistryButton
              )}
            </div>
            {!currentChemistryInfo && isBuyer && (
              <Tooltip
                className={classNames(css.closeIcon, { [css.disable]: disableCloseButton })}
                content={removeCoachTooltipMessage}
                {...closeBtnTooltipDirection}
              >
                <IconCircleClose onClick={onRemove} />
              </Tooltip>
            )}
          </>
        )}
      </div>
      <Modal
        id={`CoachCard.chemistryInvitation-${coachListingId}`}
        extraContainerClassName={css.modal}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalTitle}>
          <FormattedMessage id="CoachCard.chemistryInvitationTitle" />
        </div>
        <p>
          <FormattedMessage id="CoachCard.selectPeople" />
        </p>
        {shouldShowInvitationForm && (
          <ChemistrySessionInvitationForm
            formId={coachListingId}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            options={options}
            intl={intl}
            scheduleChemistryRequest={scheduleChemistryRequest}
          />
        )}
      </Modal>
    </>
  );
};

CoachCard.propTypes = {
  listing: object,
  onRemove: func,
  isOpacity: bool,
  disableCloseButton: bool,
  onGetCoachEmailToInvite: func,
  fetchCoachEmailRequest: bool,
  coachEmail: string,
  buyerEmail: string,
  coacheeEmail: string,
  chemistryList: array,
  onBookChemistry: func,
  scheduleChemistryRequest: bool,
  isBuyer: bool,
  removeCoachTooltipMessage: string,
  waitingCoachResponseMessage: string,
  closeBtnTooltipDirection: object,
  bookChemistryBtnTooltipDirection: object,
  isCoachRespond: bool,
};

CoachCard.defaultProps = {
  listing: null,
  onRemove: () => {},
  isOpacity: false,
  disableCloseButton: false,
  onGetCoachEmailToInvite: () => {},
  fetchCoachEmailRequest: false,
  coachEmail: null,
  buyerEmail: null,
  coacheeEmail: null,
  chemistryList: [],
  onBookChemistry: () => {},
  scheduleChemistryRequest: false,
  isBuyer: false,
  removeCoachTooltipMessage: null,
  waitingCoachResponseMessage: null,
  closeBtnTooltipDirection: { bottom: true },
  bookChemistryBtnTooltipDirection: { left: true },
  isCoachRespond: false,
};

export default CoachCard;
