/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string, array, object } from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
  ExternalLink,
  ListingLink,
} from '../../components';
import config from '../../config';

import css from './TopbarMobileMenu.module.css';
import { ADMIN_ROLE, BUYER_ROLE, COACHEE_ROLE, COACH_ROLE } from '../../marketplace-custom-config';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';

const mainLandingPagePath = '/';

const TopbarMobileMenu = props => {
  const history = useHistory();

  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
    isCoachHomePage,
    handleMoveToCoachingCultureSection,
    onClose,
    accessibleEngagementList,
    onDownloadReport,
    handleSendMedooLoginLink,
    isCoach,
    isCoachee,
    isBuyer,
    isAdmin,
    hasMedooAccount,
    hasSentMedooLoginLink,
    config,
  } = props;

 const { medooLink } = config;
 const { showMyCoachingLink } = useFlags();

  const user = ensureCurrentUser(currentUser);

  const { role } = user.attributes.profile.publicData || {};

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const signup = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const login = (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const signupOrLogin = (
    <span className={css.authenticationLinks}>
      <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    </span>
  );

  const logoutLink = (
    <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
      <FormattedMessage id="TopbarMobileMenu.logoutLink" />
    </InlineTextButton>
  );

  const kHubLink = (
    <NamedLink className={css.navigationLink} name="KnowledgeHubNavigationPage">
      <FormattedMessage id="TopbarMobileMenu.kHubLink" />
    </NamedLink>
  );

  const myCoachingLink = hasSentMedooLoginLink ? (
    <ExternalLink className={css.navigationLink} href={medooLink}>
      <FormattedMessage id="TopbarMobileMenu.myCoaching" />
    </ExternalLink>
  ) : (
    <button className={css.myCoachingLink} onClick={handleSendMedooLoginLink}>
      <FormattedMessage id="TopbarMobileMenu.myCoaching" />
    </button>
  );
  const myCoachingLinkMaybe = hasMedooAccount ? myCoachingLink : null;

  const workshopsLink = (
    <ExternalLink
      href="/workshops"
      target="_self"
      className={classNames(css.navigationLink, currentPageClass('WorkshopsPage'))}
    >
      <FormattedMessage id="TopbarMobileMenu.workshops" />
    </ExternalLink>
  );

  const blogLink = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('BlogPage'))}
      name="BlogPage"
    >
      <FormattedMessage id="TopbarMobileMenu.blog" />
    </NamedLink>
  );

  const inboxLink = (
    <NamedLink
      className={classNames(css.inbox, currentPageClass('InboxPage'))}
      name="InboxPage"
      params={{ tab: isCoach ? 'sales' : 'orders' }}
    >
      <FormattedMessage id="TopbarMobileMenu.inboxLink" />
      {notificationCountBadge}
    </NamedLink>
  );

  const profileSettingLink = (
    <NamedLink
      className={classNames(
        css.navigationLink,
        { [css.profileSettingLinkCoachee]: isCoachee },
        currentPageClass('ProfileSettingsPage')
      )}
      name="ProfileSettingsPage"
    >
      {isBuyer ? (
        <FormattedMessage id="TopbarMobileMenu.buyerProfileSettingsLink" />
      ) : (
        <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
      )}
    </NamedLink>
  );

  const coachListingLink =
    currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={classNames(css.navigationLink, currentPageClass('ListingPage'))}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarMobileMenu.coachListing" />
          </span>
        }
      />
    ) : null;

  const createEditCoachListingLink = (
    <div className={css.footer}>
      <NamedLink className={css.createNewListingLink} name="NewListingPage">
        {currentUserListing ? (
          <FormattedMessage id="TopbarDesktop.editYourListingLink" />
        ) : (
          <FormattedMessage id="TopbarDesktop.addYourListingLink" />
        )}
      </NamedLink>
    </div>
  );

  const accountSettingsLink = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
      name="AccountSettingsPage"
    >
      <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
    </NamedLink>
  );

  const notAuthenticationPage = currentPage !== 'SignupPage' && currentPage !== 'LoginPage';
  const coachLandingPageLink = notAuthenticationPage && !isCoachHomePage ? 
  <ExternalLink href='/become-a-coach' target="_self" className={css.createListingLink}>
    <span className={css.createListing}>
      <FormattedMessage id="TopbarDesktop.coachLandingPage" />
    </span>
  </ExternalLink> :
  <NamedLink className={css.createListingLink} name='SignupPage'>
    <span className={css.createListing}>
      <FormattedMessage id="TopbarDesktop.coachLandingPage" />
    </span>
  </NamedLink>;

  const manageListingLink = (
    <div className={css.footer}>
      <NamedLink className={css.createNewListingLink} name="ManageEngagementsPage">
        <FormattedMessage id="TopbarMobileMenu.manageEngagements" />
      </NamedLink>
    </div>
  );

  const coachListLink =
    accessibleEngagementList.length > 0 ? (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
        name="CoachListPage"
        params={{ engagementId: accessibleEngagementList[0] }}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarMobileMenu.coachList" />
        </span>
      </NamedLink>
    ) : null;

  const downloadReportLink = (
    <div className={css.navigationLink} onClick={onDownloadReport}>
      <FormattedMessage id="TopbarMobileMenu.downloadReportLink" />
    </div>
  );

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.link}>{workshopsLink}</div>
          <div className={css.link}>{coachLandingPageLink}</div>
          <div className={css.link}>{blogLink}</div>
        </div>
        <div className={css.footer}>{coachLandingPageLink}</div>
      </div>
    );
  }

  const headerItems = role => {
    switch (role) {
      case BUYER_ROLE:
        return (
          <>
            {logoutLink}
            {inboxLink}
            {profileSettingLink}
            {accountSettingsLink}
            {workshopsLink}
            {coachLandingPageLink}
            {blogLink}
          </>
        );

      case COACH_ROLE:
        return (
          <>
            {logoutLink}
            {inboxLink}
            {profileSettingLink}
            {accountSettingsLink}
            {showMyCoachingLink && myCoachingLinkMaybe}
            {kHubLink}
            {coachListingLink}
            {blogLink}
          </>
        );

      case ADMIN_ROLE:
        return (
          <>
            {logoutLink}
            {inboxLink}
            {profileSettingLink}
            {accountSettingsLink}
            {coachListingLink}
            {blogLink}
            {downloadReportLink}
          </>
        );

      case COACHEE_ROLE:
        return (
          <>
            {logoutLink}
            {profileSettingLink}
            {accountSettingsLink}
            {showMyCoachingLink && myCoachingLinkMaybe}
            {kHubLink}
            {coachListLink}
            {blogLink}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        {headerItems(role)}
      </div>

      {(isCoach || isAdmin) && createEditCoachListingLink}
      {isBuyer && manageListingLink}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  isCoachHomePage: false,
  handleMoveToCoachingCultureSection: () => {},
  onClose: () => {},
  accessibleEngagementList: [],
  onDownloadReport: () => {},
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  isCoachHomePage: bool,
  handleMoveToCoachingCultureSection: func,
  onClose: func,
  accessibleEngagementList: array,
  onDownloadReport: func,
  handleSendMedooLoginLink: func.isRequired,
  isCoach: bool.isRequired,
  isCoachee: bool.isRequired,
  isBuyer: bool.isRequired,
  isAdmin: bool.isRequired,
  hasMedooAccount: bool.isRequired,
  hasSentMedooLoginLink: bool.isRequired,
  config: object.isRequired,
};

export default TopbarMobileMenu;
