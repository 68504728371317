import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func, object, number, string, array } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
  ExternalLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import { ADMIN_ROLE, BUYER_ROLE, COACHEE_ROLE, COACH_ROLE } from '../../marketplace-custom-config';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import css from './TopbarDesktop.module.css';

const mainLandingPagePath = '/';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    hideSearchbar,
    isCoachHomePage,
    handleMoveToCoachingCultureSection,
    accessibleEngagementList,
    onDownloadReport,
    handleSendMedooLoginLink,
    isCoach,
    isCoachee,
    isBuyer,
    isAdmin,
    hasMedooAccount,
    hasSentMedooLoginLink,
    config,
  } = props;
  const [mounted, setMounted] = useState(false);
  const history = useHistory();
  const { medooLink } = config;
  const { showMyCoachingLink } = useFlags();
  
  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const role = currentUser && currentUser.attributes.profile.publicData.role;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      isShowSearchBar={!hideSearchbar && !isCoachee}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: isCoach ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const coachListingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.coachListing" />
          </span>
        }
      />
    ) : null;

  const createCoachListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createCoachListing" />
        </span>
      </NamedLink>
    );

  const manageListingLink = authenticatedOnClientSide ? (
    <NamedLink className={css.manageEngagementsLink} name="ManageEngagementsPage">
      <span className={css.manageEngagements}>
        <FormattedMessage id="TopbarDesktop.manageEngagements" />
      </span>
    </NamedLink>
  ) : null;

  const notAuthenticationPage = currentPage !== 'SignupPage' && currentPage !== 'LoginPage';
  const coachLandingPageLink = notAuthenticationPage && !isCoachHomePage ? 
    <ExternalLink href='/become-a-coach' target="_self" className={css.createListingLink} >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.coachLandingPage" />
      </span>
    </ExternalLink> :
    <NamedLink className={css.createListingLink} name='SignupPage'>
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.coachLandingPage" />
      </span>
    </NamedLink>;

  const blogLink = (
    <NamedLink className={css.blogLink} name="BlogPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.blog" />
      </span>
    </NamedLink>
  );

  const kHubLink = (
    <NamedLink className={css.coachingLink} name="KnowledgeHubNavigationPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.kHubLink" />
      </span>
    </NamedLink>
  );

  const myCoachingLinkText = (
    <span className={css.createListing}>
      <FormattedMessage id="TopbarDesktop.myCoaching" />
    </span>
  );
  const myCoachingLink = hasSentMedooLoginLink ? (
    <ExternalLink className={css.myCoachingLink} href={medooLink}>
      {myCoachingLinkText}
    </ExternalLink>
  ) : (
    <button className={css.myCoachingLink} onClick={handleSendMedooLoginLink}>
      {myCoachingLinkText}
    </button>
  );
  const myCoachingLinkMaybe = hasMedooAccount ? myCoachingLink : null;

  const workshopsLink = (
    <ExternalLink
      href="/workshops"
      target="_self"
      className={css.workshopsLink}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.workshops" />
      </span>
    </ExternalLink>
  );

  const coachListLink =
    accessibleEngagementList.length > 0 ? (
      <NamedLink
        className={classNames(css.coachingLink, currentPageClass('AccountSettingsPage'))}
        name="CoachListPage"
        params={{ engagementId: accessibleEngagementList[0] }}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.coachList" />
        </span>
      </NamedLink>
    ) : null;

  const profileSettingLink = (
    <NamedLink
      className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
      name="ProfileSettingsPage"
    >
      <span className={css.menuItemBorder} />
      {isBuyer ? (
        <FormattedMessage id="TopbarDesktop.buyerProfileSettingsLink" />
      ) : (
        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      )}
    </NamedLink>
  );

  const createEditCoachListingLink = (
    <OwnListingLink
      listing={currentUserListing}
      listingFetched={currentUserListingFetched}
      className={css.yourListingsLink}
    >
      <span className={css.menuItemBorder} />
      {currentUserListing ? (
        <FormattedMessage id="TopbarDesktop.editYourListingLink" />
      ) : (
        <FormattedMessage id="TopbarDesktop.addYourListingLink" />
      )}
    </OwnListingLink>
  );

  const accountSettingsLink = (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
      name="AccountSettingsPage"
    >
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
    </NamedLink>
  );

  const downloadReportLink = (
    <div className={css.yourListingsLink} onClick={onDownloadReport}>
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.downloadReportLink" />
    </div>
  );

  const logoutLink = (
    <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.logout" />
    </InlineTextButton>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">{profileSettingLink}</MenuItem>
        {(isCoach || isAdmin) && (
          <MenuItem key="EditListingPage">{createEditCoachListingLink}</MenuItem>
        )}
        <MenuItem key="AccountSettingsPage">{accountSettingsLink}</MenuItem>
        {isAdmin && <MenuItem key="downloadReport">{downloadReportLink}</MenuItem>}
        <MenuItem key="logout">{logoutLink}</MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const headerItems = role => {
    switch (role) {
      case BUYER_ROLE:
        return (
          <>
            {search}
            {workshopsLink}
            {manageListingLink}
            {coachLandingPageLink}
            {blogLink}
            {inboxLink}
            {profileMenu}
          </>
        );

      case COACH_ROLE:
        return (
          <>
            {search}
            {showMyCoachingLink && myCoachingLinkMaybe}
            {kHubLink}
            {coachListingLink}
            {createCoachListingLink}
            {blogLink}
            {inboxLink}
            {profileMenu}
          </>
        );

      case ADMIN_ROLE:
        return (
          <>
            {search}
            {coachListingLink}
            {createCoachListingLink}
            {blogLink}
            {inboxLink}
            {profileMenu}
          </>
        );

      case COACHEE_ROLE:
        return (
          <>
            {search}
            {showMyCoachingLink && myCoachingLinkMaybe}
            {kHubLink}
            {coachListLink}
            {blogLink}
            {profileMenu}
          </>
        );

      default:
        return (
          <>
            {search}
            {workshopsLink}
            {coachLandingPageLink}
            {blogLink}
            {signupLink}
            {loginLink}
          </>
        );
    }
  };

  return (
    <nav className={classes}>
      <ExternalLink href={isCoach ? '/become-a-coach' : '/'} target="_self" className={css.logoLink}>
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </ExternalLink>
      {headerItems(role)}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  hideSearchbar: false,
  isCoachHomePage: false,
  handleMoveToCoachingCultureSection: () => {},
  accessibleEngagementList: [],
  onDownloadReport: () => {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  hideSearchbar: bool,
  isCoachHomePage: bool,
  handleMoveToCoachingCultureSection: func,
  accessibleEngagementList: array,
  onDownloadReport: func,
  handleSendMedooLoginLink: func.isRequired,
  isCoach: bool.isRequired,
  isCoachee: bool.isRequired,
  isBuyer: bool.isRequired,
  isAdmin: bool.isRequired,
  hasMedooAccount: bool.isRequired,
  hasSentMedooLoginLink: bool.isRequired,
  config: object.isRequired,
};

export default TopbarDesktop;
